.sectionContainer1 {
    border-top: 3px solid #e8a628;
    margin-bottom: 3rem;
    overflow: hidden;
}

.title {
    display: flex;
    justify-content: center;
    font-size: 4rem;
}

.imageContainer1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.carouselImage1 {
    /* width: 100%; */
    /* Imposta la larghezza al 100% */
    height: auto;
    max-width: 200px;
    margin: 10px;
    /* Imposta una larghezza massima per le immagini, se necessario */
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {


    .title {
        display: flex;
        justify-content: center;
        font-size: 3rem;
    }

    .imageContainer1 {
        display: flex;
        /* flex-direction: column; */
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;

    }

    .carouselImage1 {
        /* width: 100%; */
        /* Imposta la larghezza al 100% */
        height: auto;
        max-width: 200px;
        margin: 10px;
        /* Imposta una larghezza massima per le immagini, se necessario */
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .title {
        display: flex;
        justify-content: center;
        font-size: 2rem;
    }

    .imageContainer1 {
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .carouselImage1 {
        /* width: 100%; */
        /* Imposta la larghezza al 100% */
        height: auto;
        max-width: 200px;
        margin: 10px;
        /* Imposta una larghezza massima per le immagini, se necessario */
    }
}