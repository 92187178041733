.container {
    margin: 0 10vw;
}

.hero {
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
    border-radius: 0px 0px 25px 25px;
}

.heroImage {
    width: 50%;
    max-width: 650px;
    height: auto;
    margin: 50px;
}

.textHeroContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.heroPresentazione {
    width: 90%;
    max-width: 800px;
    height: max-content;
    max-height: fit-content;
    background-color: #f9de59;
    color: black;
    padding: 15px;
    margin: 20px auto;
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: bold;
    border-radius: 25px;
    -webkit-box-shadow: 0px 0px 25px 1px lightGrey;
    box-shadow: 0px 0px 25px 1px lightGrey;

}

.heroLink {
    display: inline-block;
    padding: 10px 20px;
    background-color: #e8a628;
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
}

.heroLink:hover {
    background-color: #3900ff;
}


.servicesSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-top: 5px solid #e8a628;
}

.servicesTitle {
    padding: 25px;
    display: flex;
    justify-content: center;
    color: black;
    font-size: 4rem;
}

.servicesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 40px;
}

.service {
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: 40vh;
    margin-bottom: 20px;
    background-color: #f9de59;
    padding: 20px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 25px 1px lightGrey;
    box-shadow: 0px 0px 10px 1px lightGrey;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

}




.service:hover {
    transform: scale(1.05);
    -webkit-box-shadow: 0px 0px 25px 1px lightGrey;
    box-shadow: 0px 0px 10px 1px lightGrey;

}



.service:last-child {
    margin-right: 0;
}

.serviceImage {
    height: 100%;
    /* Imposta un'altezza fissa */
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0px 0px 25px 1px #e8a628;
    box-shadow: 0px 0px 10px 1px #e8a628;
}


.serviceInfo {
    background-color: #f9de59;
    color: black;
    padding: 10px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: bold;
    border-radius: 25px;
    -webkit-box-shadow: 0px 0px 25px 1px #e8a628;
    box-shadow: 0px 0px 10px 1px #e8a628;

}

.serviceHeading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: black;
    text-align: center;
}


.clientSection {
    display: none;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {

    .container {
        margin: 0 auto;

    }

    .hero {
        /* background-color: #f9de59; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 40px;

        z-index: -1;
    }

    .heroImage {
        width: 60%;
        max-width: 650px;
        height: auto;

    }

    .heroPresentazione {
        width: auto;
        max-width: max-content;
        height: max-content;
        max-height: fit-content;
        background-color: #f9de59;
        color: black;
        padding: 10px;
        bottom: 0;
        right: 0;
        margin: 50px;
        font-size: 16px;
        line-height: 1.4;
        font-weight: bold;
        border-radius: 25px;
        -webkit-box-shadow: 0px 0px 25px 1px lightGrey;
        box-shadow: 0px 0px 25px 1px lightGrey;

    }

    .servicesTitle {
        padding: 25px;
        display: flex;
        justify-content: center;
        color: black;
        font-size: 3rem;
    }

    .service {
        width: calc(50% - 20px);
        /* Imposta la larghezza delle cards su schermi più piccoli */
    }

    .clientCarouselSection {
        display: none;
    }

    .clientSection {
        display: contents;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {

    .container {
        margin: 0 auto;
    }


    .hero {
        /* background-color: #f9de59; */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 20px;
        z-index: -1;
    }

    .heroImage {
        width: 80%;
        max-width: 650px;
        height: auto;

    }


    .heroPresentazione {
        width: auto;
        max-width: max-content;
        height: max-content;
        max-height: fit-content;
        background-color: #f9de59;
        color: black;
        padding: 10px;
        bottom: 0;
        right: 0;
        margin: 0px 10px 15px 10px;
        font-size: 12px;
        line-height: 1.4;
        font-weight: bold;
        border-radius: 25px;
        -webkit-box-shadow: 0px 0px 25px 1px lightGrey;
        box-shadow: 0px 0px 25px 1px lightGrey;

    }

    .servicesTitle {
        padding: 25px;
        display: flex;
        justify-content: center;
        color: black;
        font-size: 2rem;
    }


    .service {
        width: calc(80% - 20px);

        /* Imposta la larghezza delle cards su schermi ancora più piccoli */
    }

    .serviceHeading {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 10px;
        color: black;
        text-align: center;
    }


    .clientCarouselSection {
        display: none;
    }

    .clientSection {
        display: contents;
    }
}