.footer {
    background-color: #e8a628;
    color: #333;
    padding: 20px 0;
    /* Aumenta lo spazio interno per una maggiore visibilità */
    text-align: center;
    /* Centra il testo */
    font-weight: bold;
    /* Aggiungi grassetto al testo */
}

.footerContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* Distribuisci uniformemente il contenuto */
    max-width: 1200px;
    /* Limita la larghezza massima del contenitore */
    margin: 0 auto;
    /* Centra il contenitore */
}

.footerSection {
    flex: 1 1 300px;
    align-items: center;
    margin-bottom: 20px;
    /* Aggiungi spazio inferiore tra le sezioni */
}

.footerHeading {
    font-size: 24px;
    /* Aumenta leggermente la dimensione del testo per maggiore enfasi */
    margin-bottom: 20px;
    color: #333;
}

.footerText {
    color: #333;
    margin-bottom: 10px;
    /* Aggiungi spazio inferiore tra i testi */
}

.footerLink {
    color: #007bff;
    text-decoration: none;
}

.footerLink:hover {
    color: #3900ff;
}

.footerFixNumber {
    display: flex;
    justify-content: center;
    /* Centra i numeri di telefono */
}

.socialLinks {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    /* Centra i link social */
}

.footerBottom {
    border-top: 1px solid #333;
    margin-top: 20px;
    /* Aumenta lo spazio sopra il footer bottom */
    padding-top: 20px;
    /* Aumenta lo spazio interno per una migliore visibilità */
    font-size: 14px;
    /* Riduci leggermente la dimensione del testo per una migliore proporzionalità */
}