/* Termoidraulica.css */

.termoidraulicaPageContainer {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    min-height: 80vh;
}

.termoidraulicaPageTitle {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
}

.termoidraulicaPageContent {
    font-size: 1.1rem;
    line-height: 1.6;
}