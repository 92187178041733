.nav {
    background-color: #e8a628;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    z-index: 2;
    height: 5vh;
}

.navList {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}


.navItem {
    margin-right: 20px;
}

.navLink {
    color: black;
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    transition: color 0.3s ease;
}

.logo {
    width: auto;
    height: 40px;
}


.navLink:hover {
    color: #3900ff;
}

/* NavBar.css */
.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

.dropdown:hover .dropdown-content {
    display: block;
}



/* Schermi che sono 600px o più piccoli */
@media screen and (max-width: 600px) {
    .nav {
        padding: 5px 10px;
    }


    .logo {
        display: none;
        /* Nascondi il logo */
    }

    .navLink {
        font-size: 1.5rem;
        /* Riduci la dimensione del testo */
    }
}

/* Schermi che sono 400px o più piccoli */
@media screen and (max-width: 400px) {


    .navLink {
        font-size: 1.2rem;
        /* Ulteriormente riduci la dimensione del testo */
    }
}